<template>
  <div class="news-write" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <span>협업 지원</span>
      </div>
      <div class="desc font-sm">
        <span>프로젝트의 협업 지원 관련 글을 작성해주세요</span>
      </div>
      <div class="form">
        <div class="form-group">
          <select :id="`${component.name}CrowdSourcingType`" class="form-control font-sm border-focus-point" v-model="state.form.crowdsourcingType">
            <option value="">지원 성격을 선택해주세요.</option>
            <option value="people">구인</option>
            <option value="talent">재능기부</option>
            <option value="product">물품기부</option>
            <option value="partner">파트너</option>
            <option value="etc">기타</option>
          </select>
        </div>
        <div class="subject font-md">
          <input :id="`${component.name}Title`" type="text" class="form-control border-focus-point font-sm" placeholder="제목을 입력해주세요" v-model="state.form.title" @keyup.enter="submit()"/>
        </div>
        <div class="content font-sm">
          <textarea :id="`${component.name}Content`" class="form-control border-focus-point font-sm h-25" rows="5" :placeholder="'ex) 모집 인원: 3명\n활동 일시: 2023년 2월 1일 09시\n활동 지역: 서울 시청 광장\n활동 혜택: 날아라! 슈퍼보드 기념 티셔츠 제공, 봉사 활동 시간 인정(총 16시간)'"
                    v-model="state.form.content" v-if="state.loaded"></textarea>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">작성하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import TinyEditor from "@/components/TinyEditor";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCrowdSourcingWrite";
  this.initialize = initialize;
}

export default defineComponent({
  components: {TinyEditor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams?.crowdsourcingSeq) {
        state.loaded = false;
        http.get(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/crowd-sourcing/${modalParams.crowdsourcingSeq}`).then(({data}) => {
          state.loaded = true;
          state.form.title = data.body.title;
          state.form.crowdsourcingType = data.body.crowdsourcingType;
          state.form.content = data.body.content;
        });
      }

      store.commit("tuneModal", {component, size: "xm"});
    });

    const state = reactive({
      loaded: true,
      form: {
        content: "",
        title: "",
        crowdsourcingType: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = async () => {
      const args = lib.getRenewed(state.form);

      const warn = (message, id) => {
        store.commit("setSwingMessage", message);
        id && document.getElementById(id)?.focus();
        return false;
      };

      if (!args.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      } else if (!args.content?.trim()) {
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      }

      if (!args.crowdsourcingType) {
        return warn("자원 성격을 선택해주세요.", `${component.name}CrowdSourcingType`);
      } else if (!args.title?.trim()) {
        return warn("제목을 입력해주세요.", `${component.name}CrowdSourcingTitle`);
      } else if (!args.content?.trim()) {
        return warn("내용을 입력해주세요.", `${component.name}CrowdSourcingContent`);
      }

      if (modalParams?.crowdsourcingSeq) {
        // request2
        const res2 = await http.put(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/crowd-sourcing/${modalParams.crowdsourcingSeq}`, args).catch(httpError());

        if (res2.error) {
          return;
        }

        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "협업 지원 게시글을 수정하였습니다.");
          }
        });
      } else {
        // request2
        const res2 = await http.post(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/crowd-sourcing`, args).catch(httpError());

        if (res2.error) {
          return;
        }

        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "협업 지원 게시글을 등록하였습니다.");
          }
        });
      }
    };

    return {component, state, submit};
  }
});
</script>

<style lang="scss" scoped>
.news-write {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .subject input {
        color: #000;
      }

      .content {
        background: $colorBackground;
        margin-top: $px15;
      }
    }

    > .alert {
      margin-top: $px15;
      padding: $px15;
      border: $px1 solid #eee;
      border-radius: $px4;

      > li {
        position: relative;
        padding-left: $px13;

        &:before {
          content: "-";
          position: absolute;
          left: 0;
          top: 0;
          line-height: 1.4;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }

  &.skeleton {
    > .wrapper {
      > .title > span,
      > .desc > span,
      .form .subject input {
        @include skeleton;
      }

      .content::v-deep {
        @include skeleton;

        * {
          visibility: hidden;
        }
      }
    }

    .action .btn {
      @include skeleton;
    }
  }
}
</style>